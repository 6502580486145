exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-thinking-js": () => import("./../../../src/pages/design-thinking.js" /* webpackChunkName: "component---src-pages-design-thinking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-panda-invest-js": () => import("./../../../src/pages/panda-invest.js" /* webpackChunkName: "component---src-pages-panda-invest-js" */),
  "component---src-pages-realisations-js": () => import("./../../../src/pages/realisations.js" /* webpackChunkName: "component---src-pages-realisations-js" */),
  "component---src-pages-savoir-faire-js": () => import("./../../../src/pages/savoir-faire.js" /* webpackChunkName: "component---src-pages-savoir-faire-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/Project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */)
}

